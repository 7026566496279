// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/bradwestfall/Sites/questionable/website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-feature-tour-js": () => import("/Users/bradwestfall/Sites/questionable/website/src/pages/feature-tour.js" /* webpackChunkName: "component---src-pages-feature-tour-js" */),
  "component---src-pages-index-js": () => import("/Users/bradwestfall/Sites/questionable/website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("/Users/bradwestfall/Sites/questionable/website/src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-of-service-js": () => import("/Users/bradwestfall/Sites/questionable/website/src/pages/legal/terms-of-service.js" /* webpackChunkName: "component---src-pages-legal-terms-of-service-js" */),
  "component---src-pages-our-difference-js": () => import("/Users/bradwestfall/Sites/questionable/website/src/pages/our-difference.js" /* webpackChunkName: "component---src-pages-our-difference-js" */),
  "component---src-pages-pricing-js": () => import("/Users/bradwestfall/Sites/questionable/website/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-professional-tests-index-js": () => import("/Users/bradwestfall/Sites/questionable/website/src/pages/professional-tests/index.js" /* webpackChunkName: "component---src-pages-professional-tests-index-js" */),
  "component---src-pages-professional-tests-quality-guidelines-js": () => import("/Users/bradwestfall/Sites/questionable/website/src/pages/professional-tests/quality-guidelines.js" /* webpackChunkName: "component---src-pages-professional-tests-quality-guidelines-js" */),
  "component---src-pages-signup-complete-js": () => import("/Users/bradwestfall/Sites/questionable/website/src/pages/signup-complete.js" /* webpackChunkName: "component---src-pages-signup-complete-js" */),
  "component---src-pages-signup-js": () => import("/Users/bradwestfall/Sites/questionable/website/src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/bradwestfall/Sites/questionable/website/.cache/data.json")

